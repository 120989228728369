<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Consulta de documentos
            </div>

            <v-row v-if="loading"  justify="center">
                <v-progress-linear class="my-2"
                indeterminate
                rounded
                ></v-progress-linear>
            </v-row>
            <v-row v-if="!encontrado"  justify="center">
                <span class="my-2" style="display: flex">
                    <v-icon>mdi-file-alert</v-icon>
                    Archivo no encontrado
                </span>
            </v-row>
            <v-row v-if="encontrado && !loading" justify="center">
                <v-col cols="12" sm="11" md="10" lg="9" xl="5">
                    <pdf v-if="firmado" 
                        :src="'data:application/pdf;base64,'+ doc" 
                        :page="1" 
                        style="width: 100%; border-style:solid; border-width: 1px; border-color: silver">
                    </pdf>
                    <div v-else class="doc-img">
                        <v-img :src="'data:image/jpg;base64,'+ doc"></v-img>
                    </div>
                </v-col>
            </v-row>
            
            <v-row justify="center">
                    <!-- <v-btn
                    :loading="loading || downloading"
                    :disabled="!encontrado"
                    color="primary"
                    class="mr-2 mt-6 mb-4"
                    @click="descargarDocumento">
                    Descargar
                    </v-btn> -->
                    <v-btn
                    to="/documentos-consulta"
                    class="mt-6 mb-4">
                    Salir
                    </v-btn>
                </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import pdf from 'vue-pdf'
export default ({
    components: {
        pdf
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            downloading: false,
            codCarta: null,
            firmado: false,
            encontrado: true,
            doc: null,
            docName: ''
        }
    },
    watch: {
    },
    mounted() {
        this.codCarta = this.$route.params.cod;
        this.firmado = this.$route.params.firmado == 1;
        this.loadData();
    },
    methods: {
      getDocFromApi(){
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'CONSULTA_DOC',
            CodCarta: this.codCarta,
            Firmado: this.firmado
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                this.encontrado = false;
            }
          });
        })
      },
      loadData(){
        this.getDocFromApi()
        .then(data => {
          if(data.item === '') { this.encontrado = false; }
          this.doc = data.item.File;
          this.docName = data.item.FileName
        });
      },
    //   formatDate (value) {
    //     if(value == null) { return ''; }
    //     const date = new Date(value);
    //     return String(date.getDate()).padStart(2, "0") + '/' + 
    //         String(date.getMonth() + 1).padStart(2, "0")  + '/' + 
    //         date.getFullYear();
    //   },
    //   descargarDocumento(){
    //     this.downloading = true;

    //     // Datos del fichero
    //     const contentType = this.codCarta + (this.firmado ? 'application/pdf' : 'image/jpg');
        
    //     // Covertir de base 64 a Blob
    //     const byteCharacters = atob(this.doc);
    //     const byteNumbers = new Array(byteCharacters.length);
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
        
    //     const byteArray = new Uint8Array(byteNumbers);
    //     const blob = new Blob([byteArray], {type: contentType});

    //     // Descarga del fichero
    //     const url = window.URL.createObjectURL(blob);
    //     var a = document.createElement("a"); 
    //     document.body.appendChild(a);
    //     a.setAttribute('style', 'display: none');
    //     a.href = url;
    //     a.download = this.docName; // Nombre del fichero
    //     a.click();
    //     a.remove();

    //     this.downloading = false;
    //   }
    } 
})
</script>

<style scoped>
    .doc-img {
        border: 1px solid silver;
    }
</style>
